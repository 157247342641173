.about-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* min-height: 23.938rem; */
    padding: 4rem 4rem 2rem 4rem;
  }

  .about-section .about-container {
    width: 50%;
  }


  .about-section .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23.0625rem;
    height: 23.9375rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: #485840;
    /* width: 50%; */
  }
  
  
  @media (max-width: 768px) {
    .about-section {
      margin-top: 1rem;
      padding: 1rem;
      flex-direction: column;
    }
    .about-section .about-container {
      flex-direction: column;
      width: auto;
    }


    .about-section .logo-container {
      width: 100%;
      height: 15.9375rem;
      display: none;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .about-section {
      margin: 1.5rem;
      padding: 1.5rem;
    }
  }

  .about-section .title {
    width: 50vw;
    font-family: 'Hurricane';
    font-weight: 400;
    font-size: 4.688rem;
    padding: 0;
    margin: 0;
    line-height: 4.3rem;
  }
  
  /* Adjust font size for smaller screens */
  @media (max-width: 1024px) {
    .about-section .title {
      font-size: 4.344rem;
    }
  }
  
  @media (max-width: 768px) {
    .about-section .title {
      font-size: 4.172rem;
    }
  }
  
  @media (max-width: 576px) {
    .about-section .title {
      font-size: 3.586rem;
      line-height: 2.3rem;
      width: 100vw;
      padding: 0 1rem;
    }
  }

  
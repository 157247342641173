.animated-paws {
    position: absolute;
    bottom: 0;
    left: 20%;
}
.ajax-loader1, .ajax-loader2, .ajax-loader3, .ajax-loader4 {
    position: absolute;
    transform-origin: 50% 50%;
    font-size: 80px;
    width: 1em;
    height: 3em;
}
.ajax-loader1 .paw, .ajax-loader2 .paw, .ajax-loader3 .paw, .ajax-loader4 .paw {
    width: 1.5em;
    height: 1em;
    animation: 10050ms pawAnimation ease-in-out forwards;
    opacity: 0;
}
.ajax-loader1 .paw svg, .ajax-loader2 .paw svg, .ajax-loader3 .paw svg, .ajax-loader4 .paw svg {
    width: 100%;
    height: 100%;
}
/* .ajax-loader1 .paw .icon, .ajax-loader2 .paw .icon, .ajax-loader3 .paw .icon, .ajax-loader4 .paw .icon {
    fill: #d31145;
} */
.ajax-loader1 .paw:nth-child(odd), .ajax-loader2 .paw:nth-child(odd), .ajax-loader3 .paw:nth-child(odd), .ajax-loader4 .paw:nth-child(odd) {
    transform: rotate(0deg);
}
.ajax-loader1 .paw:nth-child(even), .ajax-loader2 .paw:nth-child(even), .ajax-loader3 .paw:nth-child(even), .ajax-loader4 .paw:nth-child(even) {
    transform: rotate(0deg) scaleX(-1) translate(15%, -50%);
}
.ajax-loader1 .paw:nth-child(1), .ajax-loader2 .paw:nth-child(1), .ajax-loader3 .paw:nth-child(1), .ajax-loader4 .paw:nth-child(1) {
    animation-delay: 2.25s;
    transform: rotate(0deg) translate3d(-4%, -48%, 0);
}
.ajax-loader1 .paw:nth-child(1), .ajax-loader2 .paw:nth-child(1), .ajax-loader3 .paw:nth-child(1), .ajax-loader4 .paw:nth-child(1) {
    animation-delay: 2.25s;
    transform: rotate(-8deg) translate3d(30%, -50%, 0);
}
.ajax-loader1 .paw:nth-child(2), .ajax-loader2 .paw:nth-child(2), .ajax-loader3 .paw:nth-child(2), .ajax-loader4 .paw:nth-child(2) {
    animation-delay: 2s;
    transform: rotate(0deg) scaleX(-1) translate3d(10%, -100%, 0);
}
.ajax-loader1 .paw:nth-child(2), .ajax-loader2 .paw:nth-child(2), .ajax-loader3 .paw:nth-child(2), .ajax-loader4 .paw:nth-child(2) {
    animation-delay: 2s;
    transform: rotate(-8deg) scaleX(-1) translate3d(-30%, -120%, 0);
}
.ajax-loader1 .paw:nth-child(3), .ajax-loader2 .paw:nth-child(3), .ajax-loader3 .paw:nth-child(3), .ajax-loader4 .paw:nth-child(3) {
    animation-delay: 1.75s;
    transform: rotate(0deg) translate3d(-12%, -144%, 0);
}
.ajax-loader1 .paw:nth-child(3), .ajax-loader2 .paw:nth-child(3), .ajax-loader3 .paw:nth-child(3), .ajax-loader4 .paw:nth-child(3) {
    animation-delay: 1.75s;
    transform: rotate(0deg) translate3d(45%, -144%, 0);
}
.ajax-loader1 .paw:nth-child(4), .ajax-loader2 .paw:nth-child(4), .ajax-loader3 .paw:nth-child(4), .ajax-loader4 .paw:nth-child(4) {
    animation-delay: 1.5s;
    transform: rotate(0deg) scaleX(-1) translate3d(20%, -200%, 0);
}
.ajax-loader1 .paw:nth-child(4), .ajax-loader2 .paw:nth-child(4), .ajax-loader3 .paw:nth-child(4), .ajax-loader4 .paw:nth-child(4) {
    animation-delay: 1.5s;
    transform: rotate(0deg) scaleX(-1) translate3d(-32%, -200%, 0);
}
.ajax-loader1 .paw:nth-child(5), .ajax-loader2 .paw:nth-child(5), .ajax-loader3 .paw:nth-child(5), .ajax-loader4 .paw:nth-child(5) {
    animation-delay: 1.25s;
    transform: rotate(0deg) translate3d(-20%, -240%, 0);
}
.ajax-loader1 .paw:nth-child(5), .ajax-loader2 .paw:nth-child(5), .ajax-loader3 .paw:nth-child(5), .ajax-loader4 .paw:nth-child(5) {
    animation-delay: 1.25s;
    transform: rotate(0deg) translate3d(40%, -240%, 0);
}
.ajax-loader1 .paw:nth-child(6), .ajax-loader2 .paw:nth-child(6), .ajax-loader3 .paw:nth-child(6), .ajax-loader4 .paw:nth-child(6) {
    animation-delay: 1s;
    transform: rotate(0deg) scaleX(-1) translate3d(30%, -300%, 0);
}
.ajax-loader1 .paw:nth-child(6), .ajax-loader2 .paw:nth-child(6), .ajax-loader3 .paw:nth-child(6), .ajax-loader4 .paw:nth-child(6) {
    animation-delay: 1s;
    transform: rotate(0deg) scaleX(-1) translate3d(-30%, -300%, 0);
}
.ajax-loader1 .paw:nth-child(7), .ajax-loader2 .paw:nth-child(7), .ajax-loader3 .paw:nth-child(7), .ajax-loader4 .paw:nth-child(7) {
    animation-delay: 0.75s;
    transform: rotate(0deg) translate3d(-28%, -336%, 0);
}
.ajax-loader1 .paw:nth-child(7), .ajax-loader2 .paw:nth-child(7), .ajax-loader3 .paw:nth-child(7), .ajax-loader4 .paw:nth-child(7) {
    animation-delay: 0.75s;
    transform: rotate(1deg) translate3d(7%, -336%, 0);
}
.ajax-loader1 .paw:nth-child(8), .ajax-loader2 .paw:nth-child(8), .ajax-loader3 .paw:nth-child(8), .ajax-loader4 .paw:nth-child(8) {
    animation-delay: 0.5s;
    transform: rotate(0deg) scaleX(-1) translate3d(40%, -400%, 0);
}
.ajax-loader1 .paw:nth-child(8), .ajax-loader2 .paw:nth-child(8), .ajax-loader3 .paw:nth-child(8), .ajax-loader4 .paw:nth-child(8) {
    animation-delay: 0.5s;
    transform: rotate(1deg) scaleX(-1) translate3d(8%, -400%, 0);
}
.ajax-loader1 .paw:nth-child(9), .ajax-loader2 .paw:nth-child(9), .ajax-loader3 .paw:nth-child(9), .ajax-loader4 .paw:nth-child(9) {
    animation-delay: 0.25s;
    transform: rotate(0deg) translate3d(-36%, -432%, 0);
}
.ajax-loader1 .paw:nth-child(10), .ajax-loader2 .paw:nth-child(10), .ajax-loader3 .paw:nth-child(10), .ajax-loader4 .paw:nth-child(10) {
    animation-delay: 0s;
    transform: rotate(0deg) scaleX(-1) translate3d(50%, -500%, 0);
}
.no-cssanimations .ajax-loader1 .paw, .no-cssanimations .ajax-loader2 .paw, .no-cssanimations .ajax-loader3 .paw, .no-cssanimations .ajax-loader4 .paw {
    opacity: 1;
}
.ajax-loader1 {
    top: 15%;
    left: 50%;
    transform: rotate(10deg) translate(-50%, 0%);
}
.ajax-loader2 {
    top: 15%;
    right: 20%;
    transform: rotate(-10deg) translate(-50%, 0%);
}
.ajax-loader3 {
    top: 45%;
    left: 30%;
    transform: rotate(150deg) translate(-50%, 0%);
}
.ajax-loader4 {
    top: 45%;
    right: 35%;
    transform: rotate(200deg) translate(-50%, 0%);
}
@keyframes pawAnimation {
    0% {
        opacity: 1;
   }
    50% {
        opacity: 0;
   }
    100% {
        opacity: 0;
   }
}
@keyframes path1 {
    0% {
        transform: rotate(0deg) scaleX(-1) translate3d(0%, -50%, 0);
   }
    30% {
        transform: rotate(20deg) scaleX(-1) translate3d(-35%, -60%, 0);
   }
    100% {
        transform: rotate(20deg) scaleX(-1) translate3d(0%, -80%, 0);
   }
}

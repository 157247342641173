.book-now-btn {
    background: #ffffff !important;
    font-family: 'Montserrat' !important;
    font-weight: 700 !important;
    color: #AB9370 !important;
}

.wrapper {
    text-align: center;
    width: 100%;
}
.wrapper span {
    float: right;
    cursor: pointer;
}
.wrapper__content {
    /* padding: 40px; */
}
.wrapper__content input {
    padding: 15px;
    height: 50px;
    border: none;
    border-radius: 5px 0 0 5px;
    width: 50%;
    margin: 0;
    background: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
}
.wrapper__content input:focus {
    outline: none;
    box-shadow: 0 0 10px #d37968;
}
.wrapper__content button {
    background: #ffffff;
    /* border: 0; */
    border-radius: 0 5px 5px 0;
    text-transform: uppercase;
    padding: 15px;
    height: 50px;
    position: relative;
    border: 1px solid #ffffff;
    font-family: 'Montserrat' !important;
    font-weight: 700 !important;
    color: #AB9370 !important;
}
.wrapper__content p {
    margin-bottom: 10px;
}

.footer-image-comtainer {
    position: relative;
    height: 30vh;
    max-height: 25rem;
    
}

.footer-image {
    width: 100vw;
    height: 30vh;
    max-height: 40rem;
    position: absolute;
    bottom: 0;
    left: -1.5rem;
   
}

.footer-image img {
    width: 100vw;
    height: 100%;
}

@media (max-width: 680px) {
    .footer-image-comtainer {
        position: relative;
        height: 10vh;
        max-height: 15rem;
        
    }
    
    .footer-image {
        width: 100vw;
        height: 20vh;
        max-height: 40rem;
        position: absolute;
        bottom: -5rem;
        left: -1.5rem;
       
    }
}
.animated-kudu-paws {
    position: absolute;
    bottom: 20rem;
    left: 10%;
}

.kudu-paws {
    position: fixed;
    transform-origin: 50% 50%;
    font-size: 80px;
    width: 1em;
    height: 3em;
}
.kudu-paws .paw {
    width: 1.5em;
    height: 1em;
    animation: 20050ms pawAnimation ease-in-out infinite;
    opacity: 0;
}
.kudu-paws .paw svg {
    width: 100%;
    height: 100%;
}
/* .kudu-paws .paw .icon, .kudu-loader2 .paw .icon, .kudu-loader3 .paw .icon, .kudu-loader4 .paw .icon {
    fill: #d31145;
} */
.kudu-paws .paw:nth-child(odd) {
    transform: rotate(0deg);
}
.kudu-paws .paw:nth-child(even) {
    transform: rotate(0deg) scaleX(-1) translate(15%, -50%);
}
.kudu-paws .paw:nth-child(1) {
    animation-delay: 2.25s;
    transform: rotate(0deg) translate3d(-4%, -48%, 0);
}
.kudu-paws .paw:nth-child(1) {
    animation-delay: 2.25s;
    transform: rotate(-8deg) translate3d(30%, -50%, 0);
}
.kudu-paws .paw:nth-child(2) {
    animation-delay: 2s;
    transform: rotate(0deg) scaleX(-1) translate3d(10%, -100%, 0);
}
.kudu-paws .paw:nth-child(2) {
    animation-delay: 2s;
    transform: rotate(-8deg) scaleX(-1) translate3d(-30%, -120%, 0);
}
.kudu-paws .paw:nth-child(3) {
    animation-delay: 1.75s;
    transform: rotate(0deg) translate3d(-12%, -144%, 0);
}
.kudu-paws .paw:nth-child(3) {
    animation-delay: 1.75s;
    transform: rotate(0deg) translate3d(45%, -144%, 0);
}
.kudu-paws .paw:nth-child(4) {
    animation-delay: 1.5s;
    transform: rotate(0deg) scaleX(-1) translate3d(20%, -200%, 0);
}
.kudu-paws .paw:nth-child(4) {
    animation-delay: 1.5s;
    transform: rotate(0deg) scaleX(-1) translate3d(-32%, -200%, 0);
}
.kudu-paws .paw:nth-child(5) {
    animation-delay: 1.25s;
    transform: rotate(0deg) translate3d(-20%, -240%, 0);
}
.kudu-paws .paw:nth-child(5) {
    animation-delay: 1.25s;
    transform: rotate(0deg) translate3d(40%, -240%, 0);
}
.kudu-paws .paw:nth-child(6) {
    animation-delay: 1s;
    transform: rotate(0deg) scaleX(-1) translate3d(30%, -300%, 0);
}
.kudu-paws .paw:nth-child(6) {
    animation-delay: 1s;
    transform: rotate(0deg) scaleX(-1) translate3d(-30%, -300%, 0);
}
.kudu-paws .paw:nth-child(7) {
    animation-delay: 0.75s;
    transform: rotate(0deg) translate3d(-28%, -336%, 0);
}
.kudu-paws .paw:nth-child(7) {
    animation-delay: 0.75s;
    transform: rotate(1deg) translate3d(7%, -336%, 0);
}
.kudu-paws .paw:nth-child(8) {
    animation-delay: 0.5s;
    transform: rotate(0deg) scaleX(-1) translate3d(40%, -400%, 0);
}
.kudu-paws .paw:nth-child(8) {
    animation-delay: 0.5s;
    transform: rotate(1deg) scaleX(-1) translate3d(8%, -400%, 0);
}
.kudu-paws .paw:nth-child(9) {
    animation-delay: 0.25s;
    transform: rotate(0deg) translate3d(-36%, -432%, 0);
}
.kudu-paws .paw:nth-child(10) {
    animation-delay: 0s;
    transform: rotate(0deg) scaleX(-1) translate3d(50%, -500%, 0);
}
.no-cssanimations .kudu-paws .paw {
    opacity: 1;
}

/* change rotation  */
.kudu-paws {
    top: 1rem;
    left: 5rem;
    transform: rotate(30deg) translate(0%, 0%);
}

@keyframes pawAnimation {
    0% {
        opacity: .2;
   }
    50% {
        opacity: 0;
   }
    100% {
        opacity: 0;
   }
}
@keyframes path1 {
    0% {
        transform: rotate(0deg) scaleX(-1) translate3d(0%, -50%, 0);
   }
    30% {
        transform: rotate(20deg) scaleX(-1) translate3d(-35%, -60%, 0);
   }
    100% {
        transform: rotate(20deg) scaleX(-1) translate3d(0%, -80%, 0);
   }
}

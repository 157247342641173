.loader-logo-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: #485840;
    /* width: 50%; */
    opacity: 1;
    z-index: 999;
  }
  
  
  @media (max-width: 768px) {
    
  }
  
  .fade-enter {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
  
  .fade-enter-active {
    opacity: 1;
  }
  
  .fade-exit {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
  
  .fade-exit-active {
    opacity: 0;
  }
  
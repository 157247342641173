.container{
    /* padding: 1em 0; */
    width: 100vw;
    display: flex;
    margin: 2rem 0 3rem 0;
    overflow: hidden;
  }

  @media screen and (min-width: 900px){
    .container{
      width: 100vw;
      display: flex;
    }
  }

  @media screen and (max-width: 640px){
    .container{
      width: 100vw;
      display: flex;
      margin: 1rem 0 3rem 0;
    }
  }
  
  .container .title{
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
  }
  
  .content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .content:hover .content-overlay{
    opacity: 1;
  }
  
  .content-image{
    width: 100%;
  }
  
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .content-details p{
    color: #fff;
    font-size: 0.8em;
  }
  
  .fadeIn-bottom{
    top: 80%;
  }
  
  .fadeIn-top{
    top: 20%;
  }
  
  .fadeIn-left{
    left: 20%;
  }
  
  .fadeIn-right{
    left: 80%;
  }

  .selector-btn {
    font-size: 1.5rem !important;
    font-family: 'Montserrat' !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    z-index: 10 !important;
    border: 1px solid #ffffff !important;
    border-radius: 5rem !important;
    background: rgba(72, 88, 64, .7) !important;
    /* background: rgba(255, 255, 255, .5); */
    text-transform: capitalize !important;

    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.7rem !important;
      border: none!important;
      background: none !important
    }
  }

  .selector-btn:hover {
    color: #485840 !important;
    border: 1px solid #ffffff !important;
    background: rgba(255, 255, 255, .7) !important;
    text-transform: capitalize !important;
  }

  .selector-btn-container:hover {
    transform: scale(1.005) !important;
    transition: all 3s ease-in !important;
  }

  .selector-btn a {
    text-decoration: none;
    color: inherit;
  }

  .main-header {
    height: 100vw;
    max-height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

.main-header  .title-intro {
    color: white;
    font-size: 1.75rem;
    text-align: center;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
    line-height: 2.556rem;
    z-index: 10;
    
  
    /* Adjust font-size and line-height based on screen width */
    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 2.222rem;
    }
  
    @media (max-width: 600px) {
      font-size: 1.25rem;
      line-height: 1.889rem;
    }
  
    @media (max-width: 480px) {
      font-size: 1.35rem;
      line-height: 1.556rem;
    }
  }


  .main-header .title {
    color: white;
    font-size: 6.188rem;
    text-align: center;
    font-family: 'Hurricane', sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 5.501rem;
    z-index: 10;

    /* Adjust font-size and line-height based on screen width */
    @media (max-width: 1280px) {
        font-size: 4.95rem;
        line-height: 4.401rem;
    }

    @media (max-width: 768px) {
        font-size: 3.963rem;
        line-height: 3.306rem;
    }

    /* @media (max-width: 600px) {
        font-size: 3.171rem;
        line-height: 2.736rem;
    } */

    @media (max-width: 680px) {
        font-size: 4.198rem !important;
        line-height: 3.198rem !important;
    }
  }

  .main-header  .subtitle {
    color: white;
    font-size: 6.188rem;
    text-align: center;
    font-family: 'Hurricane', sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 5.501rem;
    z-index: 10;

    /* Adjust font-size and line-height based on screen width */
    @media (max-width: 1280px) {
        font-size: 4.95rem;
        line-height: 4.401rem;
    }

    @media (max-width: 768px) {
        font-size: 3.963rem;
        line-height: 3.306rem;
    }

    @media (max-width: 600px) {
        font-size: 3.171rem;
        line-height: 2.736rem;
    }

    @media (max-width: 480px) {
        font-size: 4.538rem;
        line-height: 3.198rem;
    }
  }

  .header-container {
    /* height: 11.25rem; */
    display: flex;
    flex-direction: row;
    /* background-color: #485840; */
    color: #fff;
    position: relative;
    padding: 0 1rem;
  }
  
  @media (min-width: 600px) {
    .header-container {
      padding: 0 4rem;
    }
  }

  .header-hero {
    padding: 1rem;
    /* height: 11.25rem; */
    width: 100%;
  }
  
  .header-hero.title {
    font-size: 4.688rem;
    line-height: 6.5rem;
  /* display: flex;
  align-items: start; */
  justify-content: start;
  text-align: center;
  font-family: 'Hurricane', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  
    /* Adjust font-size and line-height based on screen width */
    @media (max-width: 1280px) {
      font-size: 3.742rem;
      line-height: 3.242rem;
    }
  
    @media (max-width: 768px) {
      font-size: 3.018rem;
      line-height: 2.224rem;
    }
  
    @media (max-width: 600px) {
      font-size: 2.414rem;
      line-height: 1.988rem;
    }
  
    @media (max-width: 480px) {
      font-size: 3.131rem;
      line-height: 1.936rem;
    }
  }

  .header-hero.description {

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.395rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.656rem;
    text-align: center;
    /* Adjust font-size and line-height based on screen width */
    @media (min-width: 1280px) {
      font-size: 1.116rem;
      line-height: 1.332rem;
      justify-content: center;
    }
  
    @media (min-width: 768px) {
      font-size: 0.893rem;
      line-height: 1.072rem;
      justify-content: end;
    }
  
    @media (max-width: 600px) {
      font-size: 0.715rem;
      line-height: 0.858rem;
    }
  
    @media (max-width: 480px) {
      font-size: 0.568rem;
      line-height: 0.684rem;
    }
  }
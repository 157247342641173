nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    /* background: rgba(0, 0, 0, .5); */
    /* background: rgba(255, 255, 255, .3); */
    /* filter: blur(8px); */

}

nav ul {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}

nav .sidebar {
    display: flex;
}



nav ul li {
    padding: 0;
    margin: 0;
}

nav a {
    text-decoration: none;
    color: #fff;
    
}

nav a img {
    width: 40vw;
    max-width: 13rem;
    margin: .5rem 0; 
}

.desktop-menu ul {
    display: flex;
}


.desktop-menu li {
    margin: 0 12px !important;
}

@media (min-width: 681px) {
    nav {
        padding: 0 4rem;
    }

    nav .sidebar {
        display: none;
    }

    nav ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .desktop-menu {
        display: flex;
    }
  }

  @media (max-width: 680px) {
    .desktop-menu {
        display: none;
    }
    
    nav .sidebar {
        display: flex;
    }
  }
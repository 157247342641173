.css-1hj9846-MuiListItem-root {
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    /* padding-left: 16px;
    padding-right: 16px; */
    padding: 0px !important;
}

.css-10hburv-MuiTypography-root {
    margin: 0;
    font-family: "Montserrat";
    font-weight: 800 !important;
}

.css-konndc-MuiListItemText-root {
    margin-left: 1rem;
}

.popup-close-button {
    width: 100%;
    background: #485840 !important;
    color: #fff !important;
}